import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cjp-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public formFieldName: string;

  @Input() parent: FormGroup;

  constructor() {}

  ngOnInit() {}
}
