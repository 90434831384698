import { Action, createAction, props } from '@ngrx/store';
import { Fund } from './fund.model';

export enum FundActionTypes {
  SetFunds = 'Set Funds',
}

export const setFundMeta = createAction(
  FundActionTypes.SetFunds,
  props<Fund>()
);
