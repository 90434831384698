import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cjp-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public formFieldName: string;

  @Input() parent: FormGroup;

  @Input()
  readonly: boolean;

  required: boolean;

  constructor() {}

  ngOnInit() {
    if (this.parent && this.parent.get(this.formFieldName)) {
      const validator = this.parent.get(this.formFieldName).validator;

      if (validator) {
        this.required = validator({} as AbstractControl).required;
      }
    }
  }
}
