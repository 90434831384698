import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Fund } from './fund.model';
import { Store, createAction } from '@ngrx/store';
import { AppState } from '../../core/reducers';
import { setFundMeta } from './fund.actions';

@Injectable({
  providedIn: 'root',
})
export class FundService {
  private _apiHost: string;

  constructor(private _httpClient: HttpClient, private store: Store<AppState>) {
    this._apiHost = environment.apiPath;
  }

  getFundInfo() {
    return this._httpClient.get(
      `${this._apiHost}/api/funds/${environment.fundSlug}`
    );
  }

  loadFundMeta() {
    const fundMetaJson = localStorage.getItem('fundMeta');

    if (!fundMetaJson) {
      this.getFundInfo().subscribe((meta: Fund) => {
        localStorage.setItem('fundMeta', JSON.stringify(meta));
        this.store.dispatch(setFundMeta(meta));
      });
    } else {
      const meta = localStorage.getItem('fundMeta');
      try {
        this.store.dispatch(setFundMeta(JSON.parse(meta)));
      } catch (e) {}
    }
  }
}
