import { AppState } from './../../../core/reducers/index';
import { Login } from './../../../core/auth/_actions/auth.actions';
import { Router } from '@angular/router';
import { LayoutConfigService } from './../../../core/_base/layout/services/layout-config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as objectPath from 'object-path';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Fund } from '../../../services/fund/fund.model';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'cjp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loaderLogo: string;
  errorMessage: string;
  fund: Fund;

  barButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Sign In',
    buttonColor: 'primary',
    barColor: 'primary',
    raised: true,
    stroked: false,
    mode: 'indeterminate',
    value: 0,
    disabled: false,
    fullWidth: false,
  };

  constructor(
    private builder: FormBuilder,
    private oauthService: OAuthService,
    private userService: UserService,
    private store: Store<{ fund: Fund }>,
    private layoutConfigService: LayoutConfigService,
    private changeRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.loginForm = builder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    const loaderConfig = layoutConfigService.getConfig('loader');
    this.loaderLogo = objectPath.get(loaderConfig, 'logo');

    store
      .select((state) => state.fund)
      .subscribe((fund: any) => {
        this.fund = fund;
      });
  }

  ngOnInit() {}

  open(content) {
    this.modalService.open(content).result;
  }

  login() {
    this.barButtonOptions.active = true;
    this.barButtonOptions.text = 'Signing In';
    this.errorMessage = null;
    this.changeRef.detectChanges();

    if (!this.loginForm.valid) {
      this.barButtonOptions.active = false;
      this.barButtonOptions.text = 'Sign In';
      this.errorMessage = 'Invalid credentials have been entered';
      this.changeRef.detectChanges();
      return;
    }

    var formValues = this.loginForm.getRawValue();

    this.oauthService
      .fetchTokenUsingPasswordFlow(formValues.username, formValues.password)
      .then((resp) => {
        // Loading data about the user
        return this.oauthService.loadUserProfile();
      })
      .then((profile) => {
        // Using the loaded user data
        const valid = this.oauthService.hasValidAccessToken();

        if (valid) {
          this.barButtonOptions.active = false;
          this.barButtonOptions.text = 'Success';

          this.store.dispatch(
            new Login({ authToken: this.oauthService.getAccessToken() })
          );

          this.userService.loadEmployer();
          this.router.navigate(['job']);
        } else {
          this.barButtonOptions.active = false;
          this.barButtonOptions.text = 'Sign In';
          this.errorMessage = 'Invalid credentials have been entered';
          this.changeRef.detectChanges();
        }
      })
      .catch((err) => {
        this.barButtonOptions.active = false;
        this.barButtonOptions.text = 'Sign In';
        this.errorMessage = 'Invalid credentials have been entered';
        this.changeRef.detectChanges();
      });
  }
}
