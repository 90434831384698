import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, Employer } from './user.model';
import { setAuthEmployer } from './user.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/reducers';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _apiHost: string;
  user: User;

  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
    this._apiHost = environment.apiPath;
  }

  getByUbc(ubc: string) {
    return this.httpClient.get(`${this._apiHost}/api/users/${ubc}`);
  }

  storeSteward(user: User) {
    this.user = user;
  }

  getSteward() {
    return this.user;
  }

  getEmployer() {
    return this.httpClient.get(`${this._apiHost}/api/employers/me`);
  }

  loadEmployer() {
    this.getEmployer()
      .pipe(take(1))
      .subscribe((employer: Employer) => {
        this.store.dispatch(setAuthEmployer(employer));
      });
  }
}
