// NGRX
import { routerReducer } from '@ngrx/router-store';
import {
  ActionReducerMap,
  MetaReducer,
  on,
  createAction,
  createReducer,
} from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from '../../../environments/environment';
import { setFundMeta } from '../../services/fund/fund.actions';
import { Fund } from '../../services/fund/fund.model';
import { setAuthEmployer } from '../../services/user/user.actions';

// tslint:disable-next-line:no-empty-interface
export interface AppState {}

const _fundReducer = createReducer(
  null,
  on(setFundMeta, (state, fund) => fund)
);

const _employerReducer = createReducer(
  null,
  on(setAuthEmployer, (state, employer) => employer)
);

export function fundReducer(state, action) {
  return _fundReducer(state, action);
}

export function employerReducer(state, action) {
  return _employerReducer(state, action);
}

export const defaultReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  fund: fundReducer,
  employer: employerReducer,
};

export const metaReducers: Array<MetaReducer<
  AppState
>> = !environment.production ? [storeFreeze] : [];
