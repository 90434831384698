import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { HtmlClassService } from './html-class.service';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StoreModule } from '@ngrx/store';
import {
  rolesReducer,
  permissionsReducer,
  PermissionEffects,
  RoleEffects,
} from '../core/auth';
import { EffectsModule } from '@ngrx/effects';
import { PagesModule } from '../views/pages/pages.module';
import { PartialsModule } from '../views/partials/partials.module';
import { CoreModule } from '../core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule } from '@angular/forms';
import {
  MatProgressBarModule,
  MatTabsModule,
  MatButtonModule,
  MatTooltipModule,
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { InlineSVGModule } from 'ng-inline-svg';
import {
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from '../views/theme/footer/footer.component';
import { HeaderComponent } from '../views/theme/header/header.component';
import { BrandComponent } from '../views/theme/brand/brand.component';
import { HeaderMobileComponent } from '../views/theme/header/header-mobile/header-mobile.component';
import { SubheaderComponent } from '../views/theme/subheader/subheader.component';
import { TopbarComponent } from '../views/theme/header/topbar/topbar.component';
import { AsideLeftComponent } from '../views/theme/aside/aside-left.component';
import { MenuHorizontalComponent } from '../views/theme/header/menu-horizontal/menu-horizontal.component';
import { ErrorPageComponent } from '../views/theme/content/error-page/error-page.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // topbar components
    TopbarComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,

    UserProfileComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature('roles', rolesReducer),
    StoreModule.forFeature('permissions', permissionsReducer),
    EffectsModule.forFeature([PermissionEffects, RoleEffects]),
    PartialsModule,
    CoreModule,
    PerfectScrollbarModule,
    FormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    LoadingBarModule,
    InlineSVGModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
  exports: [BaseComponent],
  providers: [HtmlClassService],
})
export class LayoutsModule {}
