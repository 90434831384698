export const environment = {
  production: false,
  isMockEnabled: false,
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  apiPath: 'https://api-qa.carpentersonline.net',
  idpPath: 'https://philly-qa.auth.carpentersonline.net',
  clientId: 'job-starts-app',
  idpRequireHttps: true,
  fundSlug: 'phl',
};
