// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'lodash';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'firstLetter',
})
export class FirstLetterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    return take(
      value.split(' ').map((n) => n[0]),
      3
    ).join('');
  }
}
