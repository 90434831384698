import { Employer } from './user.model';
import { createAction, props } from '@ngrx/store';

export enum EmployerActionTypes {
  SetAuthEmployer = 'Set Auth Employer',
}

export const setAuthEmployer = createAction(
  EmployerActionTypes.SetAuthEmployer,
  props<Employer>()
);
