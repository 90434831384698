import { AlertComponent } from './alert/alert.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiOptionButtonComponent } from './multi-option-button/multi-option-button.component';
import { MatMenuModule } from '@angular/material';
import { InputFieldComponent } from './fields/input-field/input-field.component';
import { TextareaFieldComponent } from './fields/textarea-field/textarea-field.component';
import { DateFieldComponent } from './fields/date-field/date-field.component';
import { OverviewFieldComponent } from './fields/overview-field/overview-field.component';
import { SelectFieldComponent } from './fields/select-field/select-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  exports: [
    AlertComponent,
    InputFieldComponent,
    TextareaFieldComponent,
    DateFieldComponent,
    OverviewFieldComponent,
    SelectFieldComponent,
  ],
  declarations: [
    AlertComponent,
    MultiOptionButtonComponent,
    InputFieldComponent,
    TextareaFieldComponent,
    DateFieldComponent,
    OverviewFieldComponent,
    SelectFieldComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
  ],
})
export class SharedModule {}
