import { Observable } from 'rxjs';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DefaultOAuthInterceptor implements HttpInterceptor {
  constructor(private authStorage: OAuthStorage) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    const token = this.authStorage.getItem('access_token');
    const header = `Bearer ${token}`;
    const headers = req.headers.set('Authorization', header);
    req = req.clone({ headers });

    return next.handle(req);
  }
}
