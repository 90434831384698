import { environment } from './../environments/environment';
import { Subscription } from 'rxjs';
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { FundService } from './services/fund/fund.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Metronic';
  loader: boolean;
  idpPath = environment.idpPath;
  clientId = environment.clientId;

  authConfig: AuthConfig = {
    // issuer: `${this.idpPath}/identity`,
    // redirectUri: window.location.origin + '/index.html',
    // clientId: this.clientId,
    // responseType: 'code',
    // scope: 'profile',
  };

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private fundService: FundService,
    private oauthService: OAuthService
  ) {
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(this.authConfig);
    this.oauthService.requireHttps = environment.idpRequireHttps;
    this.oauthService.redirectUri = window.location.origin + '/job';
    this.oauthService.issuer = `${this.idpPath}`;
    this.oauthService.oidc = false;
    this.oauthService.clientId = this.clientId;
    this.oauthService.responseType = 'code';
    this.oauthService.scope = 'profile carpenters-api openid';
    let url = `${this.idpPath}/.well-known/openid-configuration`;

    this.oauthService.loadDiscoveryDocument(url).then(() => {
      this.oauthService.tryLogin({});
    });

    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit(): void {
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    this.fundService.loadFundMeta();

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.splashScreenService.hide();

        window.scrollTo(0, 0);

        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
