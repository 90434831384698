import { OAuthService } from 'angular-oauth2-oidc';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultAuthGuard implements CanActivate {
  constructor(private oAuthService: OAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hasIdToken = this.oAuthService.hasValidIdToken();
    const hasAccessToken = this.oAuthService.hasValidAccessToken();

    if (!hasAccessToken) {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
    }

    return hasAccessToken;
  }
}
